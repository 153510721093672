import { IUser, IResponse } from "../interfaces";
import request from "./common";
export async function login(user: IUser): Promise<IResponse>{
  try {
    const url = "/users/login";
    const res = await request(url, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(user)
    });
    return res;
  } catch (error) {
    console.log('error: ', error)
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function checkUserUsageLimit(token: string): Promise<IResponse>{
  try {
    const url = "/users/check-usage-limit";
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
