import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Container, Text, TextEditor } from "typetailui";
import { useContext, useState } from "react";
import { IProgramEnrollment } from "../../interfaces";
import GlobalContext from "../../helpers/context";
import { enrollProgram } from "../../networking";
export default function ProgramDetails() {
  const [popUpInfo, setPopUpInfo] = useState({show: false, title: "Information", message: ""});
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const {state} = useContext(GlobalContext);

  const gotoCourseList = () => {
    navigate(`/courses/${data.programEnrollment!._id}`);
  }
  const goBack = () => {
    navigate(-1);
  }
  const onEnroll = async () => {
    try {
      const obj: IProgramEnrollment = {
        userId: state.user.userId,
        programId: data._id
      };
      const res = await enrollProgram(state.user.token, obj);
      if(res.success){
        setPopUpInfo({...popUpInfo, show: true, message: "You have enrolled this program successfully."});
        navigate(-1);
      }else{
        setPopUpInfo({...popUpInfo, show: true, message: "Please try again later."})
      }
    } catch (error) {
      setPopUpInfo({...popUpInfo, show: true, message: "Please try again later"})
    }
  };
  const showPopUp = () => {
    setPopUpInfo({...popUpInfo, show: !popUpInfo.show});
  }
  return (
    <Container className="border-2 m-2 rounded">
      <Alert onClose={showPopUp} isOpen={popUpInfo.show} title={popUpInfo.title} message={popUpInfo.message}/>
      <Text>{`${data.code}: ${data.name}`}</Text>
      {data.description && <TextEditor readOnly={true} initial={data.description?.data}/>}
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={goBack} className="mx-2"/>
        {data.programEnrollment
          ? <Button title="Structure" onClick={gotoCourseList} className="mx-2" />
          : <Button
              title="Enroll"
              onClick={onEnroll}
              className="mx-2"
            />}
      </Container>
    </Container>
  );
}
