import { IProgramEnrollment, IResponse } from "../interfaces";
import request from "./common";
export async function getPrograms(token: string): Promise<IResponse>{
  try {
    const url = "/program-enrollments";
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });

    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function enrollProgram(token: string, pe: IProgramEnrollment): Promise<IResponse>{
  try {
    const url = "/program-enrollments";
    const res = await request(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(pe)
    });

    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function getCourses(token: string, programEnrollmentId: string): Promise<IResponse>{
  try {
    const url = `/program-enrollments/${programEnrollmentId}/courses`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function cancelProgramEnrollment(token: string, programEnrollmentId: string): Promise<IResponse>{
  try {
    const url = `/program-enrollments/${programEnrollmentId}/cancel-program-enrollment`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}


export async function getEnrolledCourse(token: string, programEnrollmentId: string, courseId: string): Promise<IResponse>{
  try {
    const url = `/program-enrollments/${programEnrollmentId}/courses/${courseId}`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}


export async function startCourse(token: string, programEnrollmentId: string, courseId: string): Promise<IResponse>{
  try {
    const url = `/program-enrollments/${programEnrollmentId}/courses/${courseId}/start`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function endCourse(token: string, programEnrollmentId: string, courseId: string): Promise<IResponse>{
  try {
    const url = `/program-enrollments/${programEnrollmentId}/courses/${courseId}/end`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function getCurrentStudy(token: string, programEnrollmentId: string): Promise<IResponse>{
  try {
    const url = `/program-enrollments/${programEnrollmentId}/get-current-study`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
