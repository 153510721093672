import { createContext } from "react";
import { IGlobalContext } from "../interfaces";


const GlobalContext = createContext<IGlobalContext>({state: null, dispatch: null});
export default GlobalContext;
export const ActionType = {
  SET_STATE: "Set State",
  SET_USER: "Set User"
}

export const LS_USER_KEY = "@ta-user";
export const LS_EXAM_CURRENT_TIME = "@ta-user-exam-timer";
export const LS_EXAM_HINT = "@ta-user-exam-hint"


export interface Action {
  type: string,
  payload?: Object
}
export function reducer(state: any, action: Action): any{
  switch(action.type){
    case ActionType.SET_STATE:
      return {...action.payload};
    case ActionType.SET_USER:
      return {...state, user: action.payload};
    
    default:
      return state;
  }
}

