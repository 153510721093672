import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Button, Alert, Text, TextEditor } from "typetailui";
import { ICourseEnrollment, ProgressType } from "../../interfaces";

interface CourseDetailsParams{
  data: ICourseEnrollment;
  programEnrollmentId: string
}

export default function CourseDetails() {
  const [popUpInfo, setPopUpInfo] = useState({show: false, title: "Information", message: ""});
  const navigate = useNavigate();
  const location = useLocation();
  const { data, programEnrollmentId }: CourseDetailsParams  = location.state;
  const gotoModuleList = () => {
    navigate(`/modules/${programEnrollmentId}/${data.courseId}`);
  };
  const goBack = () => {
    navigate(-1);
  };
  const showPopUp = () => {
    setPopUpInfo({...popUpInfo, show: !popUpInfo.show});
  }
  return (
    <Container className="border-2 m-2 rounded">
      <Alert onClose={showPopUp} isOpen={popUpInfo.show} title={popUpInfo.title} message={popUpInfo.message}/>
      <Text>{`${data.course.code}: ${data.course.name}`}</Text>
      <Text>Priority: {data.course.order}</Text>
      {data.course.description &&
        <TextEditor readOnly={true} initial={data.course.description?.data} />}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={goBack} />
        {data.status !== ProgressType.Init && <Button title="Modules" onClick={gotoModuleList} />}
      </Container>
    </Container>
  );
}
