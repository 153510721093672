import { ICourse } from "./ICourse";
import { IModule } from "./IModule";
import { IQuestion, QuestionType } from "./IQuestion";
import { IResource } from "./IResource";

export enum AnswerStatus {
  Unattempted = "unattempted",
  Correct = "correct",
  Incorrect = "incorrect"
}

type MCQAnswer = {
  type: QuestionType.MCQ;
  answer: number;
}

type TextAnswer = {
  type: QuestionType.Essay | QuestionType.Programming;
  answer: string;
}

export type AnswerType = MCQAnswer | TextAnswer;


export interface IQuestionAttempt {
  questionId: string;
  status: AnswerStatus;
  grade?: number;
  userAnswer?: AnswerType;
  question?: IQuestion
}

export interface IExam{
  _id?: string;
  moduleProgressId?: string;
  resourceId: string;
  startDate?: string;
  endDate?: string;
  questions: IQuestionAttempt[];
  status?: ProgressType;
  deleted?: boolean;
  grade?: number;
  resource?: IResource;
}

export enum ProgressType {
  //sequence: init -> progressing -> complete
  Init = "initial",
  Progressing = "progressing",
  Complete = "completed",
  Failed = "failed"
}

export interface IResourceProgress {
  resourceId: string;
  status: ProgressType;
  startDate: string;
  endDate?: string;
  resource?: IResource;
}
export interface IModuleProgress {
  _id?: string;
  programEnrollmentId?: string;
  courseId: string;
  moduleId: string;
  status: ProgressType;
  startDate: string;
  endDate?: string;
  module?: IModule;
  resources: IResourceProgress[];
}

export interface ICourseEnrollment {
  courseId: string;
  startDate: string;
  endDate?: string;
  status: ProgressType;
  records?: IModuleProgress[];
  course: ICourse;
}

export interface IProgramEnrollment {
  _id?: string;
  userId: string;
  programId: string;
  status?: ProgressType;
  startDate?: string;
  enrolledCourses?: ICourseEnrollment[];
  endDate?: string;
  deleted?: boolean;
}