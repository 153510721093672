import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, {Suspense, lazy} from "react";
const Login = lazy(() => import('../components/auth/Login'));
const SignUp = lazy(() => import('../components/auth/SignUp'));
const NotFoundError = lazy(() => import('../components/NotFoundError'));

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    )
  },
  {
    path: "signup",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SignUp />
      </Suspense>
    )
  },
  {
    path: "*",
    element: <NotFoundError/>
  },
]);
export default function Auth() {
  return <RouterProvider router={router}/>;
}
