import { ICourseEnrollment, IModuleProgress } from "../../interfaces";
import { Container, TextEditor, Button, Text } from "typetailui";
import { useLocation, useNavigate } from "react-router-dom";

interface ModuleDetailsParams{
  data: IModuleProgress;
  course: ICourseEnrollment;
  programEnrollmentId: string;
}
export default function ModuleDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const {data, course, programEnrollmentId}: ModuleDetailsParams = location.state;

  const gotoResourceList = () => {
    navigate(`/resources/${programEnrollmentId}/${data.courseId}/${data._id}`);
  };
  return (
    <Container className="border-2 m-2 rounded">
      <Text>{`${course.course.code}: ${course.course.name}`}</Text>
      <Text>Title: {data.module!.title}</Text>
      <Text>Priority: {data.module!.order}</Text>
      {data.module!.description &&
        <TextEditor readOnly={true} initial={data.module!.description?.data} />}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Resources" onClick={gotoResourceList} />
      </Container>
    </Container>
  );
}
