import { useLocation, useNavigate } from "react-router-dom";
import { Container, Button, Text, TextEditor } from "typetailui";
import { IResourceProgress, ResourceType, MimeType } from "../../interfaces";
import YouTubeVideo from "../lib/YouTubeVideo";

interface ResourceDetailsParams {
  programEnrollmentId: String;
  data: IResourceProgress;
  courseId: string;
  moduleProgressId: string;
}
export default function ResourceDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, moduleProgressId, programEnrollmentId} : ResourceDetailsParams = location.state;
  const showExamList = data.resource!.type === ResourceType.Homework;
  const showVideo = data.resource?.media?.mimeType === MimeType.Mp4;
  return (
    <Container className="border-2 m-2 rounded">
      <Text>Topic: {data.resource!.topic}</Text>
      {showVideo && <YouTubeVideo url={data.resource?.media?.url!} title={data.resource!.topic}/>}
      {data.resource!.description &&
        <TextEditor readOnly={true} initial={data.resource!.description?.data} />}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={() => navigate(-1)} />
        {showExamList && <Button title="Completed Exams" onClick={() => navigate(`/exams/all/${programEnrollmentId}/${moduleProgressId}/${data.resourceId}`)} />}
      </Container>
    </Container>
  );
}
