import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import ProgramDetails from "../components/programs/ProgramDetails";
import { ActivityIndicator } from "typetailui";
import CourseDetails from "../components/courses/CourseDetails";
import ModuleDetails from "../components/modules/ModuleDetails";
import ResourceDetails from "../components/resources/ResourceDetails";
import NotFoundError from "../components/NotFoundError";

const Exam = lazy(() => import("../components/studying/Exam"));

const ProgramList = lazy(() => import("../components/programs/ProgramList"));
const ResourceList = lazy(() => import("../components/resources/ResourceList"));
const CourseList = lazy(() => import("../components/courses/CourseList"));
const ModuleList = lazy(() => import("../components/modules/ModuleList"));
const StudyBoard = lazy(() => import("../components/studying/StudyBoard"));
const ExamDetails = lazy(() => import("../components/completed-exams/ExamDetails"));
const ExamList = lazy(() => import("../components/completed-exams/ExamList"));

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ProgramList />
      </Suspense>
    )
  },
  {
    path: "/program-details",
    element: <ProgramDetails />
  },
  {
    path: "/courses/:programEnrollmentId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <CourseList />
      </Suspense>
    )
  },
  {
    path: "/course-details",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <CourseDetails />
      </Suspense>
    )
  },
  {
    path: "/modules/:programEnrollmentId/:courseId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ModuleList />
      </Suspense>
    )
  },
  {
    path: "/module-details",
    element: <ModuleDetails />
  },
  {
    path: "/resources/:programEnrollmentId/:courseId/:moduleProgressId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ResourceList />
      </Suspense>
    )
  },
  {
    path: "/resource-details",
    element: <ResourceDetails />
  },
  {
    path: "/study-board/:programEnrollmentId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <StudyBoard />
      </Suspense>
    )
  },
  {
    path: "/exam/:resourceId/:moduleProgressId/:courseId/:programEnrollmentId/:estimatedTime",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <Exam />
      </Suspense>
    )
  },
  {
    path: "/exams/all/:programEnrollmentId/:moduleProgressId/:resourceId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ExamList />
      </Suspense>
    )
  },
  {
    path: "/exams/one/:programEnrollmentId/:resourceId/:examId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ExamDetails />
      </Suspense>
    )
  },
  {
    path: "*",
    element: <NotFoundError />
  }
]);

export default function Home() {
  return <RouterProvider router={router} />;
}
