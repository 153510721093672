import { useEffect, useReducer, useState } from "react";
import "./App.css";
import GlobalContext, {
  ActionType,
  LS_USER_KEY,
  reducer
} from "./helpers/context";
import Home from "./layout/Home";
import Auth from "./layout/Auth";
import {
  ErrorBoundary,
  ReactErrorBoundary,
  Text,
  Container
} from "typetailui";
import ActivityIndicator from "./components/lib/ActivityIndicator";
import { checkUserUsageLimit } from "./networking";
function App() {
  const [state, dispatch] = useReducer(reducer, { user: null });
  const [loading, setLoading] = useState(true);
  async function checkUser() {
    try {
      setLoading(true);
      const res = localStorage.getItem(LS_USER_KEY);
      if (res) {
        const obj = JSON.parse(res);
        const ret = await checkUserUsageLimit(obj.token);
        if (!ret.success) {
          localStorage.removeItem(LS_USER_KEY);
        } else {
          dispatch({ type: ActionType.SET_USER, payload: obj });
        }
      }
    } catch (error) {}
    setLoading(false);
  }
  useEffect(() => {
    checkUser();
  }, []);
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <ErrorBoundary>
        <ReactErrorBoundary>
          {state.user &&
            <Container className="flex flex-row justify-end px-2">
              <Text>
                Welcome, {state.user.email}
              </Text>
            </Container>}
          {state.user ? <Home /> : <Auth />}
        </ReactErrorBoundary>
      </ErrorBoundary>
    </GlobalContext.Provider>
  );
}

export default App;
